import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import RoleProtectedRoute from '../components/Authentication/RoleProtectedRoute';
import { useSelector } from 'react-redux';
import { ROLES } from '../common/constants';
import DashboardContainer from '../pages/Dashboard/DashboardContainer';

const Login = lazy(() => import('../pages/Authentication/Login'));
const Logout = lazy(() => import('../pages/Authentication/Logout'));
const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));
const Exams = lazy(() => import('../pages/Exams'));
const AddEditExam = lazy(() => import('../pages/Exams/EditExam'));
const ExamReport = lazy(() => import('../pages/ExamReport/ExamReport'));

const Divisions = lazy(() => import('../pages/Setup/Divisions'));
const Centers = lazy(() => import('../pages/Setup/Centers'));
const Users = lazy(() => import('../pages/Setup/Users'));
const Posts = lazy(() => import('../pages/Setup/Posts'));
const Rrb = lazy(() => import('../pages/Setup/Rrb'));
const Dashboards = lazy(() => import('../pages/Setup/Rrb/Rrbs'));
const UsersTable = lazy(() =>
  import('../pages/Dashboard/ConrolAdmin/UsersTable')
);
const CentersTable = lazy(() =>
  import('../pages/Dashboard/ConrolAdmin/CentersTable')
);
const LogsTable = lazy(() =>
  import('../pages/Dashboard/ConrolAdmin/LogsTable')
);
const ExamsTable = lazy(() =>
  import('../pages/Dashboard/ConrolAdmin/ExamsTable')
);
const ReportsTable = lazy(() =>
  import('../pages/Dashboard/ConrolAdmin/ReportsTable')
);
const ZonesTable = lazy(() =>
  import('../pages/Dashboard/SuperAdmin/ZonesTable')
);
const PreviewExam = lazy(() => import('../pages/Exams/PreviewExam'));

const CenterCoordinateExams = lazy(() =>
  import('../pages/Exams/CenterCoordinateExams')
);
const CenterCoordinateExamsCompleted = lazy(() =>
  import('../pages/Exams/CenterCoordinateExamsCompleted')
);
const Reports = lazy(() => import('../pages/Exams/Reports'));
const Incidents = lazy(() => import('../pages/Incidents'));
const NoddleExam = lazy(() => import('../pages/Setup/NoddleExam'));

const unauthorizedRoutes = [
  {
    path: '/',
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: 'logout',
        element: <Logout />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to='/' replace={true} />,
  },
];

const authorizedRoutes = (auth, role) => {
  const userRole = auth?.role; // Extract user role from auth object

  return [
    {
      path: '/',
      element: <ProtectedRoute redirectPath={'/login'} auth={auth} />,
      children: [
        {
          index: true,
          element: <Dashboard />,
        },
        {
          path: 'dashboard',
          element: <Dashboard />,
        },
        {
          path: 'rrb-dashboards',
          element: (
            <RoleProtectedRoute
              allowedRoles={['super-admin']}
              userRole={role.slug}
            >
              <Dashboards />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'recruitment_boards/dashboard/:id',
          element: (
            <RoleProtectedRoute
              allowedRoles={['super-admin']}
              userRole={role.slug}
            >
              <DashboardContainer />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'ministry_exams',
          element: (
            <RoleProtectedRoute
              allowedRoles={['super-admin']}
              userRole={role.slug}
            >
              <NoddleExam />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'nodal_exams',
          element: (
            <RoleProtectedRoute allowedRoles={['admin']} userRole={role.slug}>
              <NoddleExam />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'exams',
          element: (
            <RoleProtectedRoute
              allowedRoles={[
                'admin',
                'member-secretary',
                'deputy-secretary',
                'assistant-secretary',
                'center-coordinator',
              ]}
              userRole={role.slug}
            >
              {role.slug === ROLES.CENTER_COORDINATOR ? (
                <CenterCoordinateExams />
              ) : (
                <Exams />
              )}
            </RoleProtectedRoute>
          ),
        },
        {
          path: '/incidents',
          element: (
            <RoleProtectedRoute
              allowedRoles={[
                'admin',
                'member-secretary',
                'deputy-secretary',
                'assistant-secretary',
              ]}
              userRole={role.slug}
            >
              <Incidents />
            </RoleProtectedRoute>
          ),
        },
        {
          path: '/completed-exams',
          element: (
            <RoleProtectedRoute
              allowedRoles={['center-coordinator']}
              userRole={role.slug}
            >
              <CenterCoordinateExamsCompleted />
            </RoleProtectedRoute>
          ),
        },
        {
          path: '/reports/:id',
          element: (
            <RoleProtectedRoute
              allowedRoles={['center-coordinator']}
              userRole={role.slug}
            >
              <Reports />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'exams/add',
          element: (
            <RoleProtectedRoute
              allowedRoles={[
                'admin',
                'member-secretary',
                'deputy-secretary',
                'assistant-secretary',
              ]}
              userRole={role.slug}
            >
              <AddEditExam />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'exams/edit/:id',
          element: (
            <RoleProtectedRoute
              allowedRoles={[
                'admin',
                'member-secretary',
                'deputy-secretary',
                'assistant-secretary',
              ]}
              userRole={role.slug}
            >
              <AddEditExam />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'exams/edit/:id/:rrb_id',
          element: (
            <RoleProtectedRoute
              allowedRoles={[
                'admin',
                'super-admin',
                'member-secretary',
                'deputy-secretary',
                'assistant-secretary',
              ]}
              userRole={role.slug}
            >
              <AddEditExam />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'exams/preview/:id',
          element: (
            <RoleProtectedRoute
              allowedRoles={[
                'admin',
                'member-secretary',
                'deputy-secretary',
                'assistant-secretary',
                'super-admin'
              ]}
              userRole={role.slug}
            >
              <PreviewExam />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'exams/reports/:id',
          element: (
            <RoleProtectedRoute
              allowedRoles={[
                'super-admin',
                'admin',
                'member-secretary',
                'deputy-secretary',
                'assistant-secretary',
                'region-coordinator',
                'additional-region-coordinator',
              ]}
              userRole={role.slug}
            >
              <ExamReport />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'divisions',
          element: (
            <RoleProtectedRoute
              allowedRoles={[
                'admin',
                'member-secretary',
                'deputy-secretary',
                'assistant-secretary',
              ]}
              userRole={role.slug}
            >
              <Divisions />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'centers',
          element: (
            <RoleProtectedRoute
              allowedRoles={[
                'admin',
                'member-secretary',
                'deputy-secretary',
                'assistant-secretary',
              ]}
              userRole={role.slug}
            >
              <Centers />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'users',
          element: (
            <RoleProtectedRoute
              allowedRoles={[
                'admin',
                'member-secretary',
                'super-admin',
                'deputy-secretary',
                'assistant-secretary',
                'director-general',
                'additional-member-staff',
                'executive-director-establishment',
                'establishment-director-i',
                'establishment-director-ii',
                'deputy-director',
              ]}
              userRole={role.slug}
            >
              <Users />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'posts',
          element: (
            <RoleProtectedRoute
              allowedRoles={[
                'super-admin',
                'director-general',
                'additional-member-staff',
                'executive-director-establishment',
                'establishment-director-i',
                'establishment-director-ii',
                'deputy-director',
              ]}
              userRole={role.slug}
            >
              <Posts />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'recruitment_boards',
          element: (
            <RoleProtectedRoute
              allowedRoles={[
                'super-admin',
                'director-general',
                'additional-member-staff',
                'executive-director-establishment',
                'establishment-director-i',
                'establishment-director-ii',
                'deputy-director',
              ]}
              userRole={role.slug}
            >
              <Rrb />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'control_users',
          element: (
            <RoleProtectedRoute
              allowedRoles={['control-admin']}
              userRole={role.slug}
            >
              <UsersTable />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'control_centers',
          element: (
            <RoleProtectedRoute
              allowedRoles={['control-admin']}
              userRole={role.slug}
            >
              <CentersTable />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'control_loginactivity',
          element: (
            <RoleProtectedRoute
              allowedRoles={['control-admin']}
              userRole={role.slug}
            >
              <LogsTable />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'control_exams',
          element: (
            <RoleProtectedRoute
              allowedRoles={['control-admin']}
              userRole={role.slug}
            >
              <ExamsTable />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'control_reports',
          element: (
            <RoleProtectedRoute
              allowedRoles={['control-admin', 'admin']}
              userRole={role.slug}
            >
              <ReportsTable />
            </RoleProtectedRoute>
          ),
        },
        {
          path: 'zones',
          element: (
            <RoleProtectedRoute
              allowedRoles={['super-admin']}
              userRole={role.slug}
            >
              <ZonesTable />
            </RoleProtectedRoute>
          ),
        },

        {
          path: 'logout',
          element: <Logout />,
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to='/' replace={true} />,
    },
  ];
};
export const Routes = (auth) => {
  const authSelector = useSelector((state) => state.rrb.authUserReducer);
  return auth ? authorizedRoutes(auth, authSelector?.role) : unauthorizedRoutes;
};
