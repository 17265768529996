import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import AnimatedComponent from '../../components/Animation/AnimatedComponent';
import StatusCapsual from '../../components/DataTable/StatusCapsule';
import { Link, useNavigate } from 'react-router-dom';
import DateCard from '../ExamReport/DateCard';
import BarChartCard from '../../components/Charts/BarChartCard';
import PresenceBarChart from '../../components/Charts/PresenceBarChart';
import IncidentCard from '../../components/Charts/IncidentCard';
import RRBContacts from './SuperAdmin/RRBContacts';
import AdminContacts from '../Dashboard/AdminContacts';
import { FormFieldSelect } from '../../components/Form/FormField';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import useIsMobile from '../../hooks/useIsMobile';
import { getAll } from '../../api/MinistryExams';
import { getDashboardDetailsByMinistryId } from '../../api/DashboardAPI';
import NoDataFound from '../../components/General/NoDataFound';
import { IconEye } from '@tabler/icons-react';

const InProgressExam = ({ data = [], rrb_id = null, setDashboard }) => {
  console.log('rrb_id================> IN PROGRESS', rrb_id);
  const isMobile = useIsMobile();
  const [exams, setExams] = useState([]);
  const [examDays, setExamDays] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rrbId, setRrbId] = useState(rrb_id);
  const defaultFilterValue = {
    exam: '',
  };
  const {
    control,
    formState: { errors: dashboardFilterError },
    watch,
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: defaultFilterValue,
  });
  const [filter, setFilter] = useState({
    _page: 1,
    _limit: 1000,
    q: '',
    _sort: 'id',
    _order: 'desc',
    // status:"1"
  });
  const getExamDaysWithMaxDays = (data) => {
    let maxDaysExam = null;
    let maxDaysCount = 0;
    console.log('data', data);
    if (data) {
      data.Exams.forEach((exam) => {
        const examDaysCount = exam.examDays.length;

        if (examDaysCount > maxDaysCount) {
          maxDaysCount = examDaysCount;
          maxDaysExam = exam;
        }
      });
      if (maxDaysExam && maxDaysExam?.examDays) {
        // Sort examDays by date in ascending order
        maxDaysExam?.examDays.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        console.log('maxDaysExam.examDays (sorted)', maxDaysExam?.examDays);
      }
      console.log('maxDaysExam.examDays', maxDaysExam?.examDays);
      return maxDaysExam ? maxDaysExam?.examDays : [];
    }
  };
  const fetchAllMinistryExams = async () => {
    const exams = await getAll(filter);
    setExams(exams.data.records);
  };
  useEffect(() => {
    fetchAllMinistryExams();
  }, []);
  const navigate = useNavigate();
  const fetchDashboardData = async (ministry_id, examDayId = null) => {
    console.log('rrb_id========================> PP PP PP', rrbId);

    setIsLoading(true);
    try {
      if (ministry_id) {
        const res = await getDashboardDetailsByMinistryId(
          ministry_id,
          examDayId,
          rrbId
        );
        console.log('res.data=========================> PP', res.data);
        setDashboard(res.data);
      }
    } catch (error) {
      console.log('🚀 ~ fetchDashboardData ~ error:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const selectedExam = watch('exam');
  const selectedExamDay = watch('examDay');

  useEffect(() => {
    setValue('examDay', []);
    if (selectedExam) {
      const examDays = getExamDaysWithMaxDays(
        exams.find((exam) => exam.id === selectedExam.value)
      );
      setExamDays(examDays);
      fetchDashboardData(selectedExam.value);
    }
  }, [selectedExam]);
  useEffect(() => {
    if (selectedExamDay) {
      console.log('selectedExamDay', selectedExamDay.value);
      fetchDashboardData(selectedExam.value, selectedExamDay.value);
    }
  }, [selectedExamDay]);
  // if (!data || data.length === 0) return;
  useEffect(() => {
    // setValue('examDay', []);
    setValue('exam', []);

    setRrbId(rrb_id);
  }, [rrb_id]);
  return (
    <>
      <Col md='12'>
        <Row className='d-flex align-items-center mb-2'>
          <Col md='8'>
            <h2 className='page-title'>Exams Stats</h2>
          </Col>
          <Col md='2'>
            <FormFieldSelect
              name='exam'
              control={control}
              options={exams.map((exam) => ({
                label: exam.exam_name,
                value: exam.id,
              }))}
              error={dashboardFilterError?.role}
              size='12'
              placeholder='Select Exam'
            />
          </Col>
          <Col md='2'>
            <FormFieldSelect
              name='examDay'
              control={control}
              options={[
                { label: 'All Exam Days', value: null },
                ...(examDays || []).map((day) => ({
                  label: moment(day.date).format('DD/MM'),
                  value: day.date,
                })),
              ]}
              error={dashboardFilterError?.role}
              size='12'
              placeholder='All Exam Days'
              disabled={!getValues('exam')}
            />
          </Col>
        </Row>
        {console.log('data=============> ABCD', data)}
        {data && data.length > 0 ? (
          data?.map((exam, examInd) => (
            <AnimatedComponent as={Card} key={examInd} className='mt-3'>
              <Card.Body className='p-3'>
                <div className=' d-flex justify-content-between'>
                  <div>
                    <h3 className='mb-1 fs-18 fw-medium'>{exam?.exam_name}</h3>
                    <Card.Text className='text-secondary mb-2'>
                      {exam?.post?.description} ({exam?.post?.name})
                    </Card.Text>
                    <StatusCapsual status={exam?.status} />
                  </div>
                  <div className='d-flex gap-3'>
                    <div>
                      <Link
                        className='btn btn-primary'
                        to={`/exams/preview/${exam?.id}?rrb=${rrb_id}`}
                        title='Preview'
                      >
                        Schedule
                      </Link>
                    </div>
                    <div>
                      <Link
                        to={`/exams/reports/${exam?.id}?rrb=${rrb_id}`}
                        className='btn btn-primary'
                      >
                        View Detailed Report
                      </Link>
                    </div>
                  </div>
                </div>
              </Card.Body>
              <Card.Body className='p-3'>
                <Row className='g-3'>
                  {exam?.examDates?.map((exmDay, dayInd) => (
                    <DateCard
                      exam={exmDay}
                      key={dayInd}
                      onClick={() =>
                        navigate(
                          `/exams/reports/${exam?.id}?oid=${exmDay?.id}&rrb=${
                            rrb_id ? rrb_id : ''
                          }`
                        )
                      }
                    />
                  ))}
                  <Col md={12}>
                    <Row className='g-3'>
                      <Col xl='6'>
                        <BarChartCard
                          title='Exam Day Reporting Progress (Center/Dates)'
                          data={exam?.examDayReportProgress}
                          height='240'
                        />
                      </Col>
                      <Col xl='6'>
                        <PresenceBarChart
                          title='Scheduling & Attendence'
                          data={exam?.examDayPresence}
                        />
                      </Col>
                      <Col md='12'>
                        <IncidentCard
                          title='Exam Incidents'
                          data={exam?.incidentCountsByDate}
                          lg
                        />
                      </Col>
                      {/* <Col md="12"> */}
                      <AdminContacts
                        examDay={selectedExamDay.value}
                        exam={selectedExam.value}
                        rrb_id={rrb_id}
                      />
                      {/* need to fix issue here  */}
                      {/* </Col> */}
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </AnimatedComponent>
          ))
        ) : (
          <NoDataFound
            message='No active exams currently. Please select exam/exam day filters to see past exam data.'
            minHeight={isMobile ? '80vh' : '50vh'}
          />
        )}
      </Col>
    </>
  );
};

export default InProgressExam;
